import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundSlider from "gatsby-image-background-slider"

const HomepageBackgroundSlider = () => (
  <BackgroundSlider
    query={useStaticQuery(graphql`
      query {
        backgrounds: allFile(
          filter: { sourceInstanceName: { eq: "homepage-backgrounds" } }
        ) {
          nodes {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `)}
    initDelay={3.5}
    transition={2}
    duration={5}
    images={["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg"]}
  />
)

export default HomepageBackgroundSlider
