import React from "react"
import { navigate, graphql } from "gatsby"
import HomepageBackgroundSlider from "../components/homepageBackgroundSlider"
import { Flex } from "rebass/styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const {
    allMenuJson: { edges: menus },
  } = data

  return (
    <Layout noScroll>
      <SEO title="Pinkemely Prague | Kavárna v Prahy | Jidlo, Zákusky a dezerty" />
      <Flex width="100%" alignItems="flex-end" justifyContent="center">
        <Flex
          mb={4}
          flexWrap="wrap"
          maxWidth={[100 * 2, 100 * 3, 100 * 3, 100 * 6]}
          width="100vw"
        >
          {menus
            .sort((a, b) => a.node.order - b.node.order)
            .slice(0, 6)
            .map(({ node: menu }) => (
              <Flex
                key={menu.id}
                justifyContent="center"
                alignItems="center"
                width={100}
                height={100}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(`/menu#${menu.hash}`)}
              >
                <img
                  src={menu.icon.publicURL}
                  alt={menu.hash}
                  width={menu.iconWidth}
                />
              </Flex>
            ))}
        </Flex>
        <HomepageBackgroundSlider />
      </Flex>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMenuJson {
      edges {
        node {
          id
          order
          hash
          icon {
            publicURL
          }
          iconWidth
        }
      }
    }
  }
`

export default IndexPage
